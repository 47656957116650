.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #ffdc67;
  position: relative;
}

.App p {
  width: 100%;
  color: #965a3e;
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 0.875rem;
}

